<template>
  <!-- Footer -->
  <div
    class="flex flex-col w-full bg-white pt-8 pb-2 items-center justify-self-center border-t border-a-neutral-dim px-4"
  >
    <div
      class="flex flex-col w-full max-w-screen-2xl"
    >
      <!-- Top Row -->
      <div
        class="grid grid-cols-2 md:grid-cols-4 w-full items-start justify-start"
      >
        <div
          class="flex flex-col w-full items-start justify-start"
        >
          <p
            class="text-a-secondary cursor-pointer font-semibold hover:underline"
          >
            Businesses
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Coupons
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Reviews & Ratings
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Find Services & Products
            Nearby
          </p>
        </div>
        <div
          class="flex flex-col w-full items-start justify-start"
        >
          <p
            class="text-a-secondary cursor-pointer font-semibold hover:underline"
          >
            Classified
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Best Classified Items
          </p>
        </div>
        <div
          class="flex flex-col w-full items-start justify-start my-2 md:my-0"
        >
          <p
            class="text-a-secondary cursor-pointer font-semibold hover:underline"
          >
            Fundraising
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Donations
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Fundraising Campaigns
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Crowdfunding
          </p>
        </div>
        <div
          class="flex flex-col w-full items-start justify-start my-2 md:my-0"
        >
          <p
            class="text-a-secondary cursor-pointer font-semibold hover:underline"
          >
            News
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            English News
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Urdu News
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Community Announcements
          </p>
          <p
            class="text-sm cursor-pointer hover:underline"
          >
            Nearby Announcements
          </p>
        </div>
      </div>
      <!-- Top Row Ends -->
      <!-- Bottom Row -->
      <div
        class="flex flex-col md:flex-row w-full items-center md:items-end justify-center"
      >
        <NuxtImg
          src="/images/main/logo.png"
          class="object-scale-down h-auto w-32"
          alt=""
        />
        <div class="flex flex-row items-center justify-center w-auto">
        <nuxt-link
          :to="{
            name: 'help-support',
          }"
          class="flex items-center hover:text-a-secondary cursor-pointer px-2 border-r-2 text-sm"
        >
          <Icon
            name="material-symbols:call-sharp"
            class="mx-0.5"
          />
          Contact Us
        </nuxt-link>
        <!-- <span class="hover:text-a-secondary cursor-pointer px-2 border-r-2">
          <span class="mdi mdi-help-circle"></span> FAQs
        </span> -->
        <!-- <span class="hover:text-a-secondary cursor-pointer px-2 border-r-2">
          <span class="mdi mdi-book-open"></span> Help & Guide
        </span> -->
        <p
          class="flex items-center hover:text-a-secondary cursor-pointer px-2 border-r-2 text-sm"
        >
          <Icon
            name="ic:outline-privacy-tip"
            class="mx-0.5"
          />
          <span class="">
            Privacy Policy</span
          >
        </p>
        <!-- <span class="hover:text-a-secondary cursor-pointer px-2">
          <span class="mdi mdi-post-outline"></span> Blog
        </span> -->
        </div>
      </div>
      <!-- Bottom Row Ends-->
    </div>
    <p
      class="mx-auto font-semibold text-xs py-2"
    >
      &copy; Alahddin 2021 All Rights
      Reserved
    </p>
  </div>
  <!-- Footer Ends -->
</template>

<script setup></script>

<style></style>
